:root {
  --bg: #f9fafb;
  --text: #282c34;
  --theme-ui-colors-background: purple;
  --theme-ui-colors-primary: pink;
  --theme-ui-colors-secondary: blue;
  --theme-ui-colors-text: yellow;
  --card: rgba(255, 255, 255, 0.4);
  --card-border: rgba(255, 255, 255, 0.2);
  --react: #61dafb;
}

.dark-mode {
  --bg: #282c34;
  --text: #f9fafb;
  --card: rgba(0, 0, 0, 0.4);
  --card-border: rgba(0, 0, 0, 0.2);
}

body {
  background: var(--bg);
  /* background-image: url('https://i.ibb.co/JdbWM6K/background.jpg'); */
  /* background-image: url('img/Kultura-umenie-homepage-1920x663.jpg'); */
  background-image: url('img/Trnava.JPG');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--text);
  height: 100vh;
  margin: 0;
  transition: background 0.2s ease;
  width: 100vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
